import { LOG_IN, LOG_OUT } from './userTypes'

export const logInAction = (userData) => (dispatch) => {
  dispatch({
    type: LOG_IN,
    payload: {
      firstname: userData.firstname,
      lastname: userData.lastname,
      email: userData.email,
      token: userData.token,
      id: userData.id,
      imgSrc: userData.imgSrc,
      notifications: userData.notifications,
    },
  })
}
export const logOutAction = () => (dispatch) => {
  dispatch({
    type: LOG_OUT,
    payload: {},
  })
}
