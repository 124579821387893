import initialState from './../initialState'
import { SET } from './commonTypes'

export default function (state = initialState, action) {
  let newState = {}

  switch (action.type) {
    case SET:
      newState = {
        sidebarShow: !state.sidebarShow,
        sidebarUnfoldable: false,
        notifications: {
          msg: state.notifications.msg,
          title: state.notifications.title,
          type: state.notifications.type,
          notificationNumber: state.notifications.notificationNumber,
        },
      }
      return newState
    default:
      return state
  }
}
