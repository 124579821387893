import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import './scss/style.scss'
import Cookies from 'universal-cookie'
import ApiHelper from './helper/ApiHelper'
import API from './Api'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logInAction } from './store/user/userActions'

import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const cookies = new Cookies()

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      waitingForAuth: true,
      notificationOpen: true,
    }
  }

  componentDidMount = () => {
    ApiHelper.getUser()
      .then((payload) => {
        const expiry = new Date()
        expiry.setDate(expiry.getDate() + 1)
        cookies.set('token', payload.token, {
          path: '/',
          expires: expiry,
        })
        this.props.logInAction(payload)
        this.setState({
          waitingForAuth: false,
        })
      })
      .catch((error) => {
        cookies.remove('token', { path: '/' })
        API.defaults.headers.common.Authorization = ''
        this.setState({
          waitingForAuth: false,
        })
      })
  }

  render() {
    return (
      <Router>
        <Suspense fallback={loading}>
          <NotificationContainer />
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </Router>
    )
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool,
  logInAction: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user,
})

export default connect(mapStateToProps, {
  logInAction,
})(App)
