import initialState from './../initialState'
import { LOG_IN, LOG_OUT } from './userTypes'

export default function (state = initialState, action) {
  let newState = {}

  switch (action.type) {
    case LOG_IN:
      newState = {
        isLoggedIn: true,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        email: action.payload.email,
        token: action.payload.token,
        id: action.payload.id,
        imgSrc: action.payload.imgSrc,
        notifications: action.payload.notifications,
      }
      return newState
    case LOG_OUT:
      newState = {
        isLoggedIn: false,
        firstname: null,
        lastname: null,
        email: null,
        token: null,
        id: null,
        imgSrc: null,
      }
      return newState
    default:
      return state
  }
}
