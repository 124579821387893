import { NotificationManager } from 'react-notifications'

const has = Object.hasOwnProperty

const subscribers = {}

export function subscribe(key, cb) {
  if (has.call(subscribers, key)) {
    subscribers[key].push(cb)
  } else {
    subscribers[key] = [cb]
  }

  // return "unsubscribe" function
  const toReturn = () => {
    subscribers[key] = subscribers[key].filter((s) => s !== cb)
  }
  return toReturn
}

export default function (store) {
  let prevState = JSON.parse(JSON.stringify(store.getState()))

  store.subscribe(() => {
    const newState = store.getState()
    if (
      newState.common.notifications.notificationNumber !==
      prevState.common.notifications.notificationNumber
    ) {
      NotificationManager[newState.common.notifications.type](
        newState.common.notifications.msg,
        newState.common.notifications.title,
      )
      prevState = JSON.parse(JSON.stringify(newState))
    }
  })

  return subscribe
}
