import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './rootReducer'
import initialState from './initialState'

import initSubscriber from './reduxSubscriber'

const middleware = [thunk]

let storeFinal

if (process.env.REACT_APP_MODE === 'development') {
  const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  })
  storeFinal = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  )
} else {
  storeFinal = createStore(rootReducer, initialState, applyMiddleware(...middleware))
}
const store = storeFinal

initSubscriber(store)

export default store
