export default {
  user: {
    isLoggedIn: false,
  },
  common: {
    sidebarShow: true,
    sidebarUnfoldable: false,
    notifications: {
      msg: '',
      title: '',
      type: '',
      notificationNumber: 0,
    },
  },
}
